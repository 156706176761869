import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {appConst} from "../constant/appConst";
import ReactPlayer from "react-player";
import Slider from "./Slider";
import "../css/Gallery.css";

const Gallery = () => {
    const { id } = useParams();

    return (
        <section className="primary gallery">

            {(id === "general") && <Slider gallery={appConst.gallery.general}  />}
            {(id === "elite") && <Slider gallery={appConst.gallery.elite}  />}
            {(id === "villa") && <Slider gallery={appConst.gallery.villa}  />}
            {(id === "video") && <ReactPlayer url='https://www.youtube.com/watch?v=MXYUPx0a6tI' playing={true} loop={true} controls={true} width='100%' height='100%' />}

        </section>
    );



};

export default Gallery;