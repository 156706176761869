export const buildingConst = {
    "apartment": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"361.3,431.4 357.4,634.8 451.9,639.3 833.7,620.4 1055.4,630.9 1198.2,620.9 1198.2,478.7 \n" +
                    "\t1108.2,465.9 1093.7,465.9 976.5,453.7 849.2,459.8 837.5,457.8 809.8,458.8 650.8,445.4 586.4,449.8"},
            {status : 1, level: 1,points:"363.9,188.1 361.3,431.4 586.4,449.8 650.8,445.4 809.8,458.8 837.5,457.8 849.2,459.8 976.5,453.7 \n" +
                    "\t1093.7,465.9 1108.2,465.9 1198.2,478.7 1198.2,301.4 1108.7,276.4 990.9,289.2 990.4,284.2 981.5,285.3 980.4,262 837.6,220.3 \n" +
                    "\t705.9,242.5 705.9,272.5 588,249.7 586.9,235.3"},


        ]
    },
    "villa": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"530.1,298.9 713.9,287.7 713.9,272.7 855.2,265.2 907.7,275.2 966.5,272.7 1149,315.2 1149,597.7 \n" +
                    "\t685.2,661.5 355.5,655.1 352.6,556.5 385.1,556.5 388.8,416.4 530.1,411.4"},
        ]
    },




}