import MapMarker from "../components/MapMarker";
import GoogleMap from "google-map-react";
import React from "react";

const appSettings = {
    projectName: "Karpasia",
    currencyUnit: "£",
    currencyUnitPosition: "before",
    areaUnit: <>m<sup>2</sup></>,
    showPrices: false,
    priceFilter: {
        unit: 10000,
        min: 0,
        max: 300000,
    },
    showIntroVideo : true,
    clientVerification : false,
    showRegisterClientPage : true,
    botSalesRep : "developer@wizio.co.uk"
};

const appConst = {
    
    menu: {
        gallery : [
            {id:1, title:'General', link: '/gallery/general'},
            {id:2, title:'Elite Apartments', link: '/gallery/elite'},
            {id:3, title:'Lavender Villas', link: '/gallery/villa'},


        ],
        location : [
            {id:1, title:'Korineum Golf Resort', link: '/location/1', lat:35.32563844768587, lng:33.51650678245197, titleDown: true},
            {id:2, title:'Alagadi Beaches', link: '/location/2', lat:35.33164107004881, lng:33.482942720249135},
            {id:3,title:'Esentepe Village',link:"/location/3", lat:35.34033587282626, lng:33.58131979259244},
            {id:4,title:'Petrol Station',link:"/location/4", lat:35.34033587282626, lng:33.58131979259244},
            {id:6,title:'Supermarket and Pharmacy',link:"/location/6", lat:35.34172453039216, lng:33.539935321812145},
            {id:7,title:'Tuncay\'in Yeri Restaurant',link:"/location/7", lat:35.34173049936661, lng:33.54192132894124,},
            {id:8,title:'Ercan International Airport',link:"/location/8", lat:35.157733827995756, lng:33.502726886094706},
            {id:9,title:'Larnaca International Airport',link:"/location/9", lat:34.87123742085969, lng:33.60783098596328,},
            {id:10,title:'Girne/Kyrenia',link:"/location/10", lat:35.34154959714187, lng:33.32196506987871 },
            {id:11,title:'Lefkosa/Nicosia',link:"/location/11", lat:35.1922324,lng:33.3273621},
            {id:12,title:'Famagusta',link:"/location/12", lat:35.120469258177735, lng:33.93727558158043},
            {id:13,title:'Karpaz',link:"/location/13", lat:35.65873697503215, lng:34.51961487309561},
            {id:14,title:'Girne State Hospital',link:"/location/14", lat:35.33772626967682, lng:33.32554894274646},
            {id:15,title:'Suat Gunsel Private Hospital',link:"/location/15", lat:35.33544283203706, lng:33.34047528411314},
            {id:16,title:'English School of Kyrenia',link:"/location/16", lat:35.31641851419015, lng:33.34336568816986},
            {id:17,title:'Acapulco Hotel & Resort',link:"/location/17", lat:35.329263729034736, lng:33.421935051012355},

        ]

    },
    gallery : {

        general : [
            {caption:'', source: require("../img/gallery/general/i1.jpg")},
            {caption:'', source: require("../img/gallery/general/i2.jpg")},

        ],
        elite : [
            {caption:'', source: require("../img/gallery/elite/i1.jpg")},
            {caption:'', source: require("../img/gallery/elite/i2.jpg")},
            {caption:'', source: require("../img/gallery/elite/i3.jpg")},
            {caption:'', source: require("../img/gallery/elite/i4.jpg")},
            {caption:'', source: require("../img/gallery/elite/i5.jpg")},
            {caption:'', source: require("../img/gallery/elite/i6.jpg")},
            {caption:'', source: require("../img/gallery/elite/i7.jpg")},
            {caption:'', source: require("../img/gallery/elite/i8.jpg")},
            {caption:'', source: require("../img/gallery/elite/i9.jpg")},
        ],
        villa : [
            {caption:'', source: require("../img/gallery/villa/i1.jpg")},
            {caption:'', source: require("../img/gallery/villa/i2.jpg")},
            {caption:'', source: require("../img/gallery/villa/i3.jpg")},
            {caption:'', source: require("../img/gallery/villa/i4.jpg")},
            {caption:'', source: require("../img/gallery/villa/i5.jpg")},
            {caption:'', source: require("../img/gallery/villa/i6.jpg")},
            {caption:'', source: require("../img/gallery/villa/i7.jpg")},
            {caption:'', source: require("../img/gallery/villa/i8.jpg")},
            {caption:'', source: require("../img/gallery/villa/i9.jpg")},
            {caption:'', source: require("../img/gallery/villa/i10.jpg")},
            {caption:'', source: require("../img/gallery/villa/i11.jpg")},
            {caption:'', source: require("../img/gallery/villa/i12.jpg")},
            {caption:'', source: require("../img/gallery/villa/i13.jpg")},
            {caption:'', source: require("../img/gallery/villa/i14.jpg")},
            {caption:'', source: require("../img/gallery/villa/i15.jpg")},

        ],
    },
    brochure : [
        {image: require('../img/brochure/b0.jpg')},
        {image: require('../img/brochure/b1.jpg')},
        {image: require('../img/brochure/b2.jpg')},
        {image: require('../img/brochure/b3.jpg')},
        {image: require('../img/brochure/b4.jpg')},
        {image: require('../img/brochure/b5.jpg')},
        {image: require('../img/brochure/b6.jpg')},
        {image: require('../img/brochure/b7.jpg')},
        {image: require('../img/brochure/b8.jpg')},
        {image: require('../img/brochure/b9.jpg')},
        {image: require('../img/brochure/b10.jpg')},
        {image: require('../img/brochure/b11.jpg')},
        {image: require('../img/brochure/b12.jpg')},
        {image: require('../img/brochure/b13.jpg')},
        {image: require('../img/brochure/b14.jpg')},
        {image: require('../img/brochure/b15.jpg')},
        {image: require('../img/brochure/b16.jpg')},
        {image: require('../img/brochure/b0.jpg')},
    ],

    status : {
        available : 1,
        reserved : 2,
        sold : 3,
        not_released : 4
    },
    getStatusTitle : function(id){
        let str = "";
        if(id === 1)  str = "Available";
        if(id === 2)  str = "Reserved";
        if(id === 3)  str = "Sold";
        if(id === 4)  str = "Not Released";

        return str;
    },
    role : {
        sales_rep : 1,
        sales_manager : 2,
        general_manager : 3,
        broker : 4
    },
    showModal : function(className){
        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".modal."+className).classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");
    },
    closeModal : function(className){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".modal."+className).classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");
    }

};

export {
    appSettings,
    appConst,
};