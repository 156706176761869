import React from 'react';
import HTMLFlipBook from "react-pageflip";
import {appConst} from "../constant/appConst";
import '../css/Brochure.css';

function Brochure() {
    const brochure = appConst.brochure;

  return (

    <section className = "primary brochure">
        <div className="flipbook-container">
            <HTMLFlipBook width={600}
                          height={791}


                           >

                {
                    brochure.map((b,i) => (
                        <div className="flipbook-page" key={i}><img src={b.image} /></div>
                    ))
                }
            </HTMLFlipBook>
        </div>

    </section>

  );
}

export default Brochure;



