import React, {useContext, useEffect, useState} from 'react';
import AppDataContext from "./context/AppDataContext";
import {withRouter,useHistory} from "react-router-dom";
import FilterContext from "./context/FilterContext";
import PreviewImage from "./PreviewImage";
import AlertContext from "./context/AlertContext";
import UserContext from "./context/UserContext";
import ClientContext from "./context/ClientContext";
import ConfirmContext from "./context/ConfirmContext";
import {appSettings} from "../constant/appConst";
function MainView(props)
{
    const {alert,setAlert} = useContext(AlertContext);

  const { appData, setAppData} = useContext(AppDataContext);
  const { filter, setFilter} = useContext(FilterContext);
  const [previewImage, setPreviewImage] = useState(null);
    const {user,setUser} = useContext(UserContext);
    const {client,setClient} = useContext(ClientContext);
    const history = useHistory();
  function goBuildingPage(buildingName)
  {
      let id = 0;
      appData.buildings.map(building => {
          if(building.instance_name === buildingName) return id = building.id;
      });
    props.history.push("/building/"+ id);
  }


    useEffect(()=>{
        if(props.loaded360 && appData.loaded) {
            for (let i = 1; i < (window.totalBuilding + 1); i++ ) {
                window.exportRoot.hover["m"+i].user = user;

            }
        }

    },[user])

  useEffect(() => {

    if(props.loaded360 && appData.loaded)
    {
        
        let timeOut;
        for (let i = 1; i < (window.totalBuilding + 1); i++ ) {

            window.exportRoot.hover["m"+i].addEventListener("click", function(e){
              if(!appData.buildingStatuses.soldBuildings.includes(i))
              {
                  console.log("//// ",user)
                  if(appSettings.showRegisterClientPage)
                      (e.currentTarget.user) ? goBuildingPage("b"+i) : props.history.push("/register")
                  else
                      goBuildingPage("b"+i)
              }

              else
                  setAlert("Sorry! This building is completely sold");

          });
        }

        for (let i = 1; i < (window.totalFacility + 1); i++ ) {

            window.exportRoot.hover["pm"+i].addEventListener("click", function(e){
                if(appSettings.showRegisterClientPage)
                    (user) ? props.history.push("/facility/"+ i) : props.history.push("/register"); //;
                else
                    props.history.push("/facility/"+ i)

            });
            window.exportRoot.hover["pm"+i].addEventListener("mouseover", function(e){
                clearTimeout(timeOut)
                setPreviewImage({
                    id:i,x:window.exportRoot.hover["pm"+i].x,y:window.exportRoot.hover["pm"+i].y
                });

                //props.history.push("/facility/"+ i);
            });
            window.exportRoot.hover["pm"+i].addEventListener("mouseout", function(e){
                timeOut = setTimeout(()=> {
                    if(document.querySelector(".preview-image"))
                        document.querySelector(".preview-image").classList.remove("show");
                },500)

            });

        }

    }

  },[props.loaded360,appData.loaded])

    useEffect(() => {
        if(appData.isFilterModeActive)
        {

            let filteredBuildings = [];
            appData.filteredProperties.forEach(function(properties){
                filteredBuildings.push(properties.building.instance_name);
            })

            let filteredBuildingBadgeMap = [];

            filteredBuildings.forEach(function(building){
                //o bina ile ilk kez karşılaşıyor ise 1 oluyor değer
                if(!filteredBuildingBadgeMap[building])
                    filteredBuildingBadgeMap[building] = 1;
                // ondan sonraki her karşılaşmada o binanın değerini 1 artırıyoruz
                else
                    filteredBuildingBadgeMap[building]++;
            })
            if(window.exportRoot)
            {
                window.toggleAllLabel(true);
                window.setBadge(filteredBuildingBadgeMap)
            }

        }
        else
        {
            if(window.exportRoot) window.clearBadge();
        }

    },[appData.filteredProperties,appData.isFilterModeActive])




    return (
        <div>
            {(previewImage) && <PreviewImage imageObj={previewImage} />}
        </div>
    );


}

export default withRouter(MainView);

