import {appSettings} from "./appConst";

export const appUtil = {
    formatPrice : function(price){
        const n = 0,x = 3, s=".", c=",";
            let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = price.toFixed(Math.max(0, ~~n));
            let currency = appSettings.currencyUnit;
            if (appSettings.currencyUnitPosition == "before") {
                return currency + (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
            }
            else {
                return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ',')) + currency;
            }
            

    },

    isEmpty : function (obj) {
        for(var prop in obj) {
            if(obj.hasOwnProperty(prop))
                return false;
        }
        return true;
    },
    trimString : function(str,charLength){

        return str.replace(new RegExp("^(.{"+charLength+"}[^\s]*).*"), "$1");
    },
    convertToSlug : function (text)
    {
        return text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^\w-]+/g,'')
            ;
    },
    convertSecondsToMinutes : function(seconds){
        let minutes = 0;
        if(seconds > 59) {
            minutes = Math.floor(seconds / 60);
            seconds = seconds % 60;
        }
        return minutes + " : " + seconds;
    }
}