export const propertyConst = {
    slider : {
        "2-bed-apt" : [
            {source : require("../img/property_slider/2-bed-apt/i1.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i2.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i3.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i4.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i5.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i6.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i7.jpg")},
            {source : require("../img/property_slider/2-bed-apt/i8.jpg")},
        ],
        "3-bed-villa_coastal" : [
            {source : require("../img/property_slider/3-bed-villa_coastal/i1.jpg")},
            {source : require("../img/property_slider/3-bed-villa_coastal/i2.jpg")},
            {source : require("../img/property_slider/3-bed-villa_coastal/i3.jpg")},
            {source : require("../img/property_slider/3-bed-villa_coastal/i4.jpg")},
            {source : require("../img/property_slider/3-bed-villa_coastal/i5.jpg")},
            {source : require("../img/property_slider/3-bed-villa_coastal/i6.jpg")},
            {source : require("../img/property_slider/3-bed-villa_coastal/i7.jpg")},
        ],
        "3-bed-villa_modern" : [
            {source : require("../img/property_slider/3-bed-villa_modern/i1.jpg")},
            {source : require("../img/property_slider/3-bed-villa_modern/i2.jpg")},
            {source : require("../img/property_slider/3-bed-villa_modern/i3.jpg")},
            {source : require("../img/property_slider/3-bed-villa_modern/i4.jpg")},
            {source : require("../img/property_slider/3-bed-villa_modern/i5.jpg")},
            {source : require("../img/property_slider/3-bed-villa_modern/i6.jpg")},
            {source : require("../img/property_slider/3-bed-villa_modern/i7.jpg")},
        ],


    },
    thumb : {
        "2-bed-apt" : [
            {source : require("../img/property_thumbs/2-bed-apt/i1.jpg")},

        ],
        "3-bed-villa_coastal" : [
            {source : require("../img/property_thumbs/3-bed-villa_coastal/i1.jpg")},

        ],
        "3-bed-villa_modern" : [
            {source : require("../img/property_thumbs/3-bed-villa_modern/i1.jpg")},

        ],
    },
    vr360 : {
        "2-bed-apt" : "",
        "3-bed-villa_coastal" : "https://wizio.co.uk/projects/cy/kensington/karpasia/360/coastal/",
        "3-bed-villa_modern" : "https://wizio.co.uk/projects/cy/kensington/karpasia/360/modern/",

    }
}