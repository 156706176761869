import React, {useEffect, useState} from 'react';
import GoogleMap from 'google-map-react';

import '../css/Location.css';
import MapMarker from "./MapMarker";
import {Link, useParams} from "react-router-dom";
import {appConst} from "../constant/appConst";
function Location() {

    const { id } = useParams();
    const [map,setMap] = useState();
    const [maps,setMaps] = useState();
    const [route,setRoute] = useState();
    const [path,setPath] = useState();
    const [distance,setDistance] = useState(0);
    const [distanceFly,setDistanceFly] = useState(0);
    const [duration,setDuration] = useState(0);
    const [durationFly,setDurationFly] = useState(0);
    const [activeMarkerId,setActiveMarkerId] = useState(0);
    const projectLocation = {
        lat: 35.33778219375099,
        lng: 33.52138279068377
    };
    const waterColor = getComputedStyle(document.documentElement).getPropertyValue('--map-water-color').trim();

    const defaultProps = {
        key : 'AIzaSyBQOpCLaqy7upmskq1IXNs1w_F9kW-I0tM',
        center: projectLocation,
        zoom: 10,
        createMapOptions: function (maps) {
            return {
                panControl: true,
                mapTypeControl: false,
                scrollwheel: false,
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "weight": "2.00"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#9c9c9c"
                            }
                        ]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 45
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#7b7b7b"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },

                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": waterColor
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": waterColor
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#070707"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    }
                ]
            }
        }

    };
    console.log(defaultProps);
    function handleApiLoaded (map, maps) {
        setMap(map);
        setMaps(maps);
    };

    function onMarkerClick(lat, lng, markerId){
        setActiveMarkerId(markerId);
        setPath(null);
        if(route) route.setMap(null);

        if(map && maps) {

            const directionsService = new maps.DirectionsService();
            const directionsDisplay = new maps.DirectionsRenderer();
            directionsService.route({
                origin: new maps.LatLng(projectLocation.lat, projectLocation.lng),
                destination: new maps.LatLng(lat, lng),
                travelMode: 'DRIVING'
            }, (response, status) => {
                if (status === 'OK') {

                    directionsDisplay.setDirections(response);
                    setRoute(new maps.Polyline({
                        path: [],
                        geodesic : true,
                        strokeColor: getComputedStyle(document.documentElement).getPropertyValue('--map-route-color').trim(),
                        strokeOpacity: 1.0,
                        strokeWeight: 5,
                        editable: false,
                        map: map
                    }));
                    setPath(response.routes[0].overview_path);

                    setDistance(parseFloat((response.routes[0].legs[0].distance.value/1000)).toFixed(1))
                    setDuration(parseFloat((response.routes[0].legs[0].duration.value/60)).toFixed(1))

                } else {
                    window.alert('Directions request failed due to ' + status);
                }
            });

            var bounds = new maps.LatLngBounds();
            bounds.extend(new maps.LatLng(projectLocation.lat, projectLocation.lng));
            bounds.extend(new maps.LatLng(lat, lng));

            let padding = window.innerWidth * 0.1;
            map.fitBounds(bounds, padding);

        }

    }

    useEffect(() => {

        if(map && maps && id){
            if(id != 0){
                onMenuItemClick(id)
            }
            else{
                if(route) route.setMap(null);
                setPath(null);
            }

        }
    },[map,maps,id])

    useEffect(() => {
        if(route && path)
        {
            for (let i = 0; i < path.length; i++) {
                setTimeout(function(singlePath) {
                    route.getPath().push(singlePath);
                }, i * 10, path[i])
            }
        }

    },[route,path])

    useEffect(() => {
        if(distance > 0 ){
            animateDistance(0.3,parseFloat(distance),100)
        }
    },[distance])

    useEffect(() => {
        if(duration > 0 ){
            animateDuration(0.3,parseFloat(duration),100)
        }
    },[duration])

    function animateDistance(start,end,duration) {
        if (start === end) return;
        let range = end - start;
        let current = start;
        let increment = end > start ? 0.3 : -1;
        let stepTime = Math.abs(Math.floor(duration / range));
        let timer = setInterval(function() {
            current += increment;
            setDistanceFly(parseFloat(current).toFixed(1));
            if (current >= end) {
                clearInterval(timer);
            }
        }, stepTime);
    }
    function animateDuration(start,end,duration) {
        if (start === end) return;
        let range = end - start;
        let current = start;
        let increment = end > start ? 0.3 : -1;
        let stepTime = Math.abs(Math.floor(duration / range));
        let timer = setInterval(function() {
            current += increment;
            setDurationFly(parseFloat(current).toFixed(1));
            if (current >= end) {
                clearInterval(timer);
            }
        }, stepTime);
    }

    function onMenuItemClick (markerId) {
        let marker = appConst.menu.location.filter((item) => item.id === parseInt(markerId))[0];
        onMarkerClick(marker.lat, marker.lng, markerId)
    }


  return (

    <section className="primary">

            <div className="map-container">
                <GoogleMap
                    bootstrapURLKeys={{ key: defaultProps.key }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    options={defaultProps.createMapOptions}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                >
                    {appConst.menu.location.map((item) => <MapMarker activeMarkerId={activeMarkerId} markerId={item.id} key={item.id} onMarkerClick={onMarkerClick} lat={item.lat} lng={item.lng} small={true} titleDown={item.titleDown} title={item.title}/>)}
                    <MapMarker  lat={projectLocation.lat} lng={projectLocation.lng}/>
                </GoogleMap>
                <div className="distance-label">{distanceFly}<span>km</span></div>
                <div className="duration-label">{durationFly}<span> min</span></div>
            </div>


    </section>

  );
}

export default Location;