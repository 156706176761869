export const floorConst = {
    "A1_F0" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"A1-2","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"A1-1","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "A1_F1" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"A1-4","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"A1-3","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "A2_F0" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"A2-2","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"A2-1","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "A2_F1" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"A2-4","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"A2-3","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "A3_F0" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"A3-2","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"A3-1","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "A3_F1" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"A3-4","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"A3-3","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "B1_F0" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"B1-2","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"B1-1","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "B1_F1" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"B1-4","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"B1-3","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "B2_F0" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"B2-2","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"B2-1","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "B2_F1" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"B2-4","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"B2-3","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "B3_F0" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"B3-2","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"B3-1","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},
    "B3_F1" :{"floorPlan":"M260,307.2h129.8V334h139.9v-99.7l139.9,0.8V129.7h108v114.8h21.8v204.4H529.7v98.8H389.8v72.9H280.1V513.3H260V307.2z M529.7,334v114.8","viewBox":"0 0 1024 768","propertyPlan":[{"number":"B3-4","path":"M260,307.2h129.8V334h139.9v213.6H389.8v72.9H280.1V513.3H260V307.2z","x":"385.3","y":"444.3"},{"number":"B3-3","path":"M669.6,235.2V129.7h108v114.8h21.8v204.4H529.7V234.4L669.6,235.2z","x":"664.5","y":"348.1"}]},


}