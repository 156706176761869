import React, {useContext, useEffect} from 'react';
import "../css/ClientMenu.css";
import AlertContext from "./context/AlertContext";
import UserContext from "./context/UserContext";
import {Link, useHistory} from "react-router-dom";
import ClientContext from "./context/ClientContext";
import FavContext from "./context/FavContext";
import ConfirmContext from "./context/ConfirmContext";
import {appConst} from "../constant/appConst";
import {apiConst} from "../constant/apiConst";
const ClientMenu = ({onClickInviteClient,onClickFavorites,showMainMenu}) => {
    const history = useHistory();
    const {user,setUser} = useContext(UserContext);
    const {client,setClient} = useContext(ClientContext);
    const {favs,setFavs} = useContext(FavContext);
    const {alert,setAlert} = useContext(AlertContext);
    const {confirm,setConfirm} = useContext(ConfirmContext);


    useEffect(() =>{
        let menu = document.getElementById('client-menu');
        if(client)
        {
            menu.style.left = "calc(" + (-menu.clientWidth) + "px - 2rem)";
        }
        else
        {
            menu.style.left = null;
            showMainMenu()
        }
    },[client])
    function onClickAdd(){
        if(!user)
        {
            setAlert("Before add a client sales rep must be logged in");

        }
        else
        {
            if(user.role.id === appConst.role.general_manager || user.role.id === appConst.role.sales_manager)
                setAlert("You are not a sales rep! Please login as a sales rep");
            else
                onClickInviteClient();
        }

    }


    function onClickLogout(){
        setConfirm({show:true,text:"You want to terminate client session",onConfirm : onConfirm})
    }

    function onConfirm(){
        apiConst.presentationTerminate(sessionStorage.getItem("presentation_id"));
        setClient(null);
        setFavs([]);
        sessionStorage.removeItem("presentation_id");
        sessionStorage.removeItem("client");
        sessionStorage.removeItem("favs");
        history.push("/");
        setAlert("Presentation is terminated");
    }



    return (

                <div id="client-menu" className="client-menu">

                    {!client && <Link className="text-with-icon client-login-button" to="/inviteClient">INVITE CLIENT</Link> }
                    {
                        client &&
                        <div className="d-flex justify-content-center">.
                            <span className="text-with-icon active-client">{client.first_name + " " + client.last_name}</span>
                            <a className="goto-fav-button" onClick={onClickFavorites} ><span></span></a>
                            <a className="red-logout-button" onClick={onClickLogout} />
                        </div>

                    }

                </div>



        );




};

export default ClientMenu;