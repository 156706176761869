import React from 'react';
import {ReactComponent as MarkerIcon} from '../img/map-marker.svg';
import {ReactComponent as MarkerPointIcon} from '../img/map-marker-point.svg';
const MapMarker = ({activeMarkerId,markerId,lat,lng,small,titleDown,title,onMarkerClick}) => {
    return (
        <div onClick={() => {
            if(markerId != 0) {
                onMarkerClick(lat, lng, markerId);
            }
        }}>
            { small ? <div className={titleDown ? "map-marker-point down" : "map-marker-point"}><span>{title}</span><MarkerPointIcon lat={lat} lng={lng}/></div> : <MarkerIcon className="map-marker" lat={lat} lng={lng}/> }
        </div>
    );
};

export default MapMarker;